// Home.js
import React from "react";
import { Card, Col, Container, Row, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Contact from './Contact';

const Home = () => {
  return (
    <Container fluid>
      <Row>
        <div className="video-box overlay">
          <video className="video w-100 d-block d-sm-none" muted autoPlay playsInline>
              <source src="/images/main.mp4" type="video/mp4" />
          </video>
          <video className="video w-100 d-none d-sm-block" muted autoPlay playsInline>
              <source src="/images/main_pc.mp4" type="video/mp4" />
          </video>
        </div>
      </Row>
      <Row className="p-2 w-md-50">
        <h2 className="text-center my-2">About Us</h2>
        <p className="text-center pt-4">
        「もっとこうすればよかった」<br />
        という想いを少しでもなくしていきたい。<br />
        <br />
        私たちは、新たな選択肢を提供します。
        働き方の多様性を広げ、人生に前向きな変化をもたらします。<br />
        <br />
        私たちが目指すのは、未来への扉を開くこと。<br />
        情熱と創造性をもって、社会の課題を解決し、<br />
        もったいないのない未来を共に築きましょう。
        </p>
        <div className="d-block ms-auto">
          <Row xs="auto">
            <Col className="ms-auto">
              <p className="text-end">株式会社ロキア<br />代表取締役<br />城間 紀子</p>
            </Col>
            <Col>
              <img src="/images/shiroma.png" className="d-block ms-auto rounded-circle" width="80" alt="test"></img>
            </Col>
          </Row>
        </div>
      </Row>
      <Row className="p-2 w-md-80">
        <h2 className="text-center p-2">Service</h2>
        <p className="text-center pt-2 px-4">
          インターネット上のマーケティングとシステム開発を得意としています。会社のフェーズや予算に合わせた提案が可能です。
        </p>
        <Row className="px-md-5 mx-auto">
          <Col className="p-3" md={4}>
            <Card style={{ width: '18rem' }} className="mx-auto me-md-0">
              <Card.Img variant="top" src="/images/1.png" />
              <Card.Body>
                <Card.Title>事業支援</Card.Title>
                <Card.Text>
                  Web活動における売上拡大・経費削減、両方の観点から支援します。
                </Card.Text>
                <Link to="/services/#management">
                  <Button variant="primary">詳細を見る</Button>
                </Link>
              </Card.Body>
            </Card>
          </Col>
          <Col className="p-3" md={4}>
            <Card style={{ width: '18rem' }} className="mx-auto">
              <Card.Img variant="top" src="/images/2.png" />
              <Card.Body>
                <Card.Title>Webマーケティング</Card.Title>
                <Card.Text>
                  上級ウェブ解析士の資格保有者が、施策立案・実行・分析までサポートします。
                </Card.Text>
                <Link to="/services/#marketing">
                  <Button variant="primary">詳細を見る</Button>
                </Link>
              </Card.Body>
            </Card>          
          </Col>
          <Col className="p-3" md={4}>
            <Card style={{ width: '18rem' }} className="mx-auto ms-md-0">
              <Card.Img variant="top" src="/images/3.png" />
              <Card.Body>
                <Card.Title>システム開発</Card.Title>
                <Card.Text>
                  マイクロサービスから機械学習まで、要件定義から開発・運用まで行います。
                </Card.Text>
                <Link to="/services/#system">
                  <Button variant="primary">詳細を見る</Button>
                </Link>
              </Card.Body>
            </Card>              
          </Col>
        </Row>
      </Row>
      <Row className="p-2 w-md-50">
        <Contact />
      </Row>
    </Container>
  );
};

export default Home;
