// About.js
import React from "react";
import { Container, Row } from "react-bootstrap";

const Privacy = () => {
  return (
    <Container className="h-100">
      <h2 className="text-center my-5">プライバシーポリシー（個人情報保護方針）</h2>
      <Row className="p-2 w-md-50">
        <p>
          株式会社ロキア（以下、「当社」という。）は、ユーザーの個人情報について以下のとおりプライバシーポリシー（以下、「本ポリシー」という。）を定めます。本ポリシーは、当社がどのような個人情報を取得し、どのように利用・共有するか、ユーザーがどのようにご自身の個人情報を管理できるかをご説明するものです。
        </p>
        <p>
          【1.事業者情報】<br />
          法人名：株式会社ロキア<br />
          住所：福岡県福岡市東区香椎照葉1-1-37<br />
          代表者：城間紀子<br />
        </p>
        <p>
          【2.個人情報の取得方法】<br />
          当社はユーザーが利用登録をするとき、氏名・生年月日・住所・電話番号・メールアドレスなど個人を特定できる情報を取得させていただきます。<br />
          お問い合わせフォームやコメントの送信時には、氏名・電話番号・メールアドレスを取得させていただきます。<br />
        </p>
        <p>
          【3.個人情報の利用目的】<br />
          取得した閲覧・購買履歴等の情報を分析し、ユーザー別に適した商品・サービスをお知らせするために利用します。また、取得した閲覧・購買履歴等の情報は、結果をスコア化した上で当該スコアを第三者へ提供します。
        </p>
        <p>
          【4.個人データを安全に管理するための措置】<br />
          当社は個人情報を正確かつ最新の内容に保つよう努め、不正なアクセス・改ざん・漏えい・滅失及び毀損から保護するため全従業員及び役員に対して教育研修を実施しています。また、個人情報保護規程を設け、現場での管理についても定期的に点検を行っています。
        </p>
        <p>
          【5.個人データの第三者提供について】<br />
          当社は法令及びガイドラインに別段の定めがある場合を除き、同意を得ないで第三者に個人情報を提供することは致しません。
        </p>
        <p>
          【6.保有個人データの開示、訂正】<br />
          当社は本人から個人情報の開示を求められたときには、遅滞なく本人に対しこれを開示します。個人情報の利用目的の通知や訂正、追加、削除、利用の停止、第三者への提供の停止を希望される方は以下の手順でご請求ください。<br />
          送付先住所<br />
          〒813-0017<br />
          福岡県福岡市東区香椎照葉1-1-37<br />
          株式会社ロキア　お問い合わせ窓口<br />
        </p>
        <p>
          【7.個人情報取り扱いに関する相談や苦情の連絡先】<br />
          当社の個人情報の取り扱いに関するご質問やご不明点、苦情、その他のお問い合わせはお問い合わせフォームよりご連絡ください。
        </p>
        <p>
          【8.SSL（Secure Socket Layer）について】<br />
          当社のWebサイトはSSLに対応しており、WebブラウザとWebサーバーとの通信を暗号化しています。ユーザーが入力する氏名や住所、電話番号などの個人情報は自動的に暗号化されます。
        </p>
        <p>
          【9.cookieについて】<br />
          cookieとは、WebサーバーからWebブラウザに送信されるデータのことです。Webサーバーがcookieを参照することでユーザーのパソコンを識別でき、効率的に当社Webサイトを利用することができます。当社Webサイトがcookieとして送るファイルは、個人を特定するような情報は含んでおりません。
          お使いのWebブラウザの設定により、cookieを無効にすることも可能です。
        </p>
        <p>
          【10.プライバシーポリシーの制定日及び改定日】<br />
          制定：2023年6月11日
        </p>
        <p>
          【11.免責事項】<br />
          当社Webサイトに掲載されている情報の正確性には万全を期していますが、利用者が当社Webサイトの情報を用いて行う一切の行為に関して、一切の責任を負わないものとします。
          当社は、利用者が当社Webサイトを利用したことにより生じた利用者の損害及び利用者が第三者に与えた損害に関して、一切の責任を負わないものとします。
        </p>
        <p>
          【12.著作権・肖像権】<br />
          当社Webサイト内の文章や画像、すべてのコンテンツは著作権・肖像権等により保護されています。無断での使用や転用は禁止されています。
        </p>
        <p>
          【13.リンク】<br />
          当社Webサイトへのリンクは、自由に設置していただいて構いません。ただし、Webサイトの内容等によってはリンクの設置をお断りすることがあります。
        </p>
      </Row>
    </Container>
  );
};

export default Privacy;
