// About.js
import React from "react";
import { Container, Row } from "react-bootstrap";

const Thanks = () => {
  return (
    <Container className="main">
      <h2 className="text-center my-5">お問い合わせありがとうございます。</h2>
      <Row className="p-2 w-md-50">
        お問い合わせありがとうございました。<br />内容を確認して担当より折り返しご連絡いたします。
      </Row>
    </Container>
  );
};

export default Thanks;
