// Services.js
import React, { useEffect } from "react";
import { Button, Container, Row } from "react-bootstrap";
import Contact from './Contact';


const Services = () => {
  useEffect(() => {
    if (window.location.hash === '#management') {
      const element = document.getElementById('management');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else if (window.location.hash === '#marketing') {
      const element = document.getElementById('marketing');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else if (window.location.hash === '#system') {
      const element = document.getElementById('system');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <Container>
      <h2 className="text-center my-5">Service</h2>
      <Row id="management" className="pb-5 px-2 w-md-50">
        <h3 className="heading3 text-center" data-number="01">事業支援</h3>
        <p className="py-3">会社のフェーズに合わせた戦略や計画表の策定、KGI/KPIの設定および、実行支援から自動レポーティングによる進捗管理まで幅広く対応可能です。</p>
        <div className="list">
          <h4 className="heading4">対応可能なご相談</h4>
          <ul>
            <li>収支計画表の作成～各月の数字管理</li>
            <li>Looker Studioを用いた自動レポーティング</li>
            <li>KGI/KPI策定に伴う行動管理</li>
          </ul>
        </div>
      </Row>
      <Row id="marketing" className="pb-5 px-2 w-md-50">
        <h3 className="heading3 text-center" data-number="02">Webマーケティング支援</h3>
        <p className="py-3">予算に合わせた売上拡大の施策立案、分析の自動レポーティングを構築することで、PDCAを早く回すことが可能です。</p>
        <div className="list">
          <h4 className="heading4">対応可能なご相談</h4>
          <ul>
            <li>マーケティング戦略策定</li>
            <li>マーケティングオートメーション導入支援</li>
            <li>マーケティング戦略策定</li>
          </ul>
        </div>
        <Button className="my-1 d-none" variant="primary">マーケティングの実績はこちら</Button>
      </Row>
      <Row id="system" className="p-2 w-md-50">
        <h3 className="heading3 text-center" data-number="03">システム開発支援</h3>
        <p className="py-3">業務効率化に向けた社内システムの開発から、顧客向けのWebアプリケーション開発まで引き受け可能です。1週間毎のスケジュール管理によって、改修を素早く対応します。</p>
        <div className="list">
          <h4 className="heading4">対応可能なご相談</h4>
          <ul>
            <li>課題ヒアリングによる要件定義</li>
            <li>最適なITツールのご提案</li>
            <li>Laravelを使用したWebアプリケーション開発</li>
            <li>Flaskを使用したWebアプリケーション開発</li>
            <li>Google Apps Scriptを使用した社内アプリケーション開発</li>
            <li>pythonを使用した社内アプリケーション開発</li>
          </ul>
        </div>
        <Button className="my-1 d-none" variant="primary">開発実績はこちら</Button>
      </Row>
      <Row className="p-2 w-md-50">
          <Contact />
      </Row>
    </Container>
  );
};

export default Services;
