// About.js
import React from "react";
import { Container, Row } from "react-bootstrap";
import Contact from './Contact';

const About = () => {
  return (
    <Container>
      <h2 className="text-center my-5">About</h2>
      <Row className="p-2 w-md-50">
        <table className="table">
          <tbody>
            <tr>
              <th scope="row">会社名</th>
              <td>株式会社ロキア</td>
            </tr>
            <tr>
              <th scope="row">設立年月</th>
              <td>2019年7月</td>
            </tr>
            <tr>
              <th scope="row">代表者</th>
              <td>城間 紀子</td>
            </tr>
            <tr>
              <th scope="row">所在地</th>
              <td>福岡県福岡市東区香椎照葉1-1-37</td>
            </tr>          
            <tr>
              <th scope="row">電話番号</th>
              <td>092-516-9028</td>
            </tr>
            <tr>
              <th scope="row">事業内容</th>
              <td>Webマーケティング支援事業<br />システム開発支援事業</td>
            </tr>
          </tbody>
        </table>
      </Row>
      <Row className="p-2 w-md-50">
          <Contact />
      </Row>
    </Container>
  );
};

export default About;
