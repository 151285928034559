import React, { useEffect, useState } from 'react';

const TextToSpeech = () => {
  const [text, setText] = useState('');
  const synth = window.speechSynthesis;

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleSpeak = () => {
    if (synth.speaking) {
      synth.cancel();
    }

    const utterance = new SpeechSynthesisUtterance(text);
    utterance.voiceURI = 'ja-JP-Wavenet-B';
    synth.speak(utterance);
  };

  return (
    <div>
      <h1>Text-to-Speech</h1>
      <textarea value={text} onChange={handleTextChange} placeholder="Enter text..." />
      <button onClick={handleSpeak} disabled={!text}>
        Speak
      </button>
    </div>
  );
};


const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = new SpeechRecognition();

const SpeechToText = () => {
  const [transcript, setTranscript] = useState('');
  const [isListening, setIsListening] = useState(false);

  const handleListen = () => {
    if (isListening) {
      recognition.stop();
    } else {
      recognition.start();
    }
  };

  recognition.onresult = (event) => {
    const speechResult = event.results[0][0].transcript;
    setTranscript(speechResult);
  };

  useEffect(() => {
    recognition.onend = () => {
      setIsListening(false);
    };
  }, []);

  return (
    <div>
      <h1>Speech-to-Text</h1>
      <button onClick={handleListen}>{isListening ? 'Stop Listening' : 'Start Listening'}</button>
      <p>{transcript}</p>
    </div>
  );
};

const App = () => {
  return (
    <div>
      <TextToSpeech />
      <SpeechToText />
    </div>
  );
};

export default App;

