import React, { useState } from "react";
import { Form, Button, Container, FloatingLabel } from "react-bootstrap";

const Contact = () => {
  const [firstName, setFirstname] = useState('');
  const [lastName, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [body, setBody] = useState('');

  const handleLastnameChange = (event) => {
    setLastname(event.target.value);
  };

  const handleFirstnameChange = (event) => {
    setFirstname(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleBodyChange = (event) => {
    setBody(event.target.value);
  };

  // フォームデータを特定のURLにPOSTする
  const handleSubmit = () => {
    if (!firstName || !lastName || !email || !body) {
      alert('入力内容を確認してください');
      return;
    }

    const data = {
      lastName: lastName,
      firstName: firstName,
      email: email,
      body: body,
    };

    fetch('https://4rmed1ah0a.execute-api.ap-northeast-1.amazonaws.com/v1/send/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        window.location.href = "/thanks";
      })
      .catch((error) => {
        console.error(error.message);
        alert('送信に失敗しました');
        window.location.reload();
      });
  };

  return (
    <Container className="main">
      <h2 className="text-center my-5">Contact</h2>
      <Form id="mailForm">
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            name="email"
            type="email"
            placeholder="example@rokia.co.jp"
            value={email}
            onChange={handleEmailChange} 
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicLastName">
          <Form.Label>姓</Form.Label>
          <Form.Control
            name="lastName"
            type="text"
            placeholder="山田"
            value={lastName}
            onChange={handleLastnameChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicFirstName">
          <Form.Label>名</Form.Label>
          <Form.Control
            name="firstName"
            type="text"
            placeholder="花子"
            value={firstName}
            onChange={handleFirstnameChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formContact">
          <Form.Label>お問い合わせ内容</Form.Label>
          <FloatingLabel controlId="floatingTextarea" label="">
            <Form.Control
              name="body"
              as="textarea"
              placeholder=""
              style={{ height: "100px" }}
              value={body}
              onChange={handleBodyChange}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formPrivacy">
          <Form.Check type="checkbox" id="privacy">
            <Form.Check.Input type="checkbox" required />
            <Form.Check.Label><a href="/privacy">プライバシーポリシー</a>に同意します。</Form.Check.Label>
          </Form.Check>
        </Form.Group>
        <Button className="d-block w-100" variant="primary" id="button" onClick={handleSubmit}>
          送信する
        </Button>
      </Form>
    </Container>
  );
};

export default Contact;
