import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Call from './pages/Call';
import Thanks from './pages/Thanks'
import Privacy from './pages/Privacy';
import { Container, Navbar, Nav } from 'react-bootstrap';
import './App.css';

const App = () => {
  // メニューの状態を管理するstate
  const [activeMenu, setActiveMenu] = useState('home');

  // メニューがクリックされたときのハンドラ関数
  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
  };

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Navbar expand="lg" bg="light" className="">
        <Container fluid>
          <Navbar.Brand onClick={() => handleMenuClick('home')} href="/">
          <img src="/images/logo.png" width="100" alt='株式会社ロキア'></img>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link className={activeMenu === 'home' ? 'nav-item active' : 'nav-item'} onClick={() => handleMenuClick('home')} href="/">Home</Nav.Link>
              <Nav.Link className={activeMenu === 'about' ? 'nav-item active' : 'nav-item'} onClick={() => handleMenuClick('about')} href="/about">About</Nav.Link>
              <Nav.Link className={activeMenu === 'services' ? 'nav-item active' : 'nav-item'} onClick={() => handleMenuClick('services')} href="/services">Services</Nav.Link>
              <Nav.Link className={activeMenu === 'contact' ? 'nav-item active' : 'nav-item'} onClick={() => handleMenuClick('contact')} href="/contact">Contact</Nav.Link>
              {/* <Nav.Link className={activeMenu === 'call' ? 'nav-item active' : 'nav-item'} onClick={() => handleMenuClick('call')} href="/call">Call</Nav.Link> */}
            </Nav>          
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* 各ページのコンポーネントを条件によって切り替え */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/call" element={<Call />} />
        <Route path="/thanks" element={<Thanks />} />
        <Route path="/privacy" element={<Privacy />} />
        {/* / のルートに対して要素を指定 */}
        <Route path="*" element={<Home />} />
      </Routes>
      <footer className='footer mt-5'>
          <p className='text-muted text-end'><a href='/privacy'>プライバシーポリシー</a> / © 2023 Copyright: rokia.co.jp</p>
      </footer>
    </Router>
  );
};
export default App;
